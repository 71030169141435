.AuctionApp {
    width: 1920px;
    height: 1080px;
    background-image: url(./images/charity-overlayV2.svg);
    position: relative;
    color: white;
}

.charity-desc {
    position: absolute;
    top: 752px;
    left: 835px;
    color: white;
    text-transform: uppercase;
    font-size: 40px;
    margin: 0;
    padding: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.total-raised-label {
    position: absolute;
    margin: 0;
    top: 820px;
    left: 835px;
    text-transform: uppercase;
    font-size: 70px;
}

.total-raised {
    font-size: 100px;
}

.item-name {
    position: absolute;
    margin: 0;
    top: 20px;
    left: 20px;
    width: 570px;
    text-align: center;
}

.bid-list-holder {
    position: absolute;
    top: 150px;
    left: 35px;
}

.item-donor {
    position: absolute;;
    margin: 0;
    top: 135px;
    left: 20px;
}

.item-name .title {
    text-overflow: ellipsis;
    font-size: 50px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.item-name .donor {
    font-style: italic;
    font-size: 30px;
    max-width: 570px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
}

.item-image-holder {
    position: absolute;
    left: 1250px;
    top: 390px;
    transform: translate(-50%, -50%);
    width: 1279px;
    height: 719px;
    mask: url("./images/image-mask.svg");
}

.big-webcam .item-image-holder {
    left: 400px;
    top: 850px;
    transform: translate(-50%, -50%);
    height: 432px;
    width: 772px;
    mask: url("./images/mask-small.svg");
}
