@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;0,700;0,900;1,500&display=swap);
body {
  margin: 0;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.AuctionApp {
    width: 1920px;
    height: 1080px;
    background-image: url(/static/media/charity-overlayV2.93b4d9e2.svg);
    position: relative;
    color: white;
}

.charity-desc {
    position: absolute;
    top: 752px;
    left: 835px;
    color: white;
    text-transform: uppercase;
    font-size: 40px;
    margin: 0;
    padding: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.total-raised-label {
    position: absolute;
    margin: 0;
    top: 820px;
    left: 835px;
    text-transform: uppercase;
    font-size: 70px;
}

.total-raised {
    font-size: 100px;
}

.item-name {
    position: absolute;
    margin: 0;
    top: 20px;
    left: 20px;
    width: 570px;
    text-align: center;
}

.bid-list-holder {
    position: absolute;
    top: 150px;
    left: 35px;
}

.item-donor {
    position: absolute;;
    margin: 0;
    top: 135px;
    left: 20px;
}

.item-name .title {
    text-overflow: ellipsis;
    font-size: 50px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.item-name .donor {
    font-style: italic;
    font-size: 30px;
    max-width: 570px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
}

.item-image-holder {
    position: absolute;
    left: 1250px;
    top: 390px;
    transform: translate(-50%, -50%);
    width: 1279px;
    height: 719px;
    -webkit-mask: url(/static/media/image-mask.a8fd6e35.svg);
            mask: url(/static/media/image-mask.a8fd6e35.svg);
}

.big-webcam .item-image-holder {
    left: 400px;
    top: 850px;
    transform: translate(-50%, -50%);
    height: 432px;
    width: 772px;
    -webkit-mask: url(/static/media/mask-small.6a938c0a.svg);
            mask: url(/static/media/mask-small.6a938c0a.svg);
}

ul.BidList {
    list-style: none;
    padding: 0;
    font-size: 30px;
}

ul.BidList li {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 570px;
}

.top-bid {
    font-size: 50px;
    margin-bottom: 20px;
}

.top-bid .bid {
    font-size: 110px;
}

.top-bid .bidder {
    display: block;
}

.bid-title {
    color: #de178b;
    text-transform: uppercase;
}
.ItemImage {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: white;
}

.ItemImage img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 300ms;
    /*background-color: #0f142d;*/
    background: url(/static/media/item_bg.fa5be862.svg);
}
.ItemImage-fade-enter{
    opacity: 0;
}
.ItemImage-fade-exit{
    opacity: 1;
}
.ItemImage-fade-enter-active{
    opacity: 1;
}
.ItemImage-fade-exit-active{
    opacity: 0;
}
.ItemImage-fade-enter-active,
.ItemImage-fade-exit-active{
    transition: opacity 300ms;
}
